

















































import { defineComponent } from '@nuxtjs/composition-api';

interface VideoSource {
  id: string;
  filename: string;
}

interface Props extends Blok {
  source: VideoSource[];
  thumbnail: BlokFieldAsset;
  autoplay: boolean;
  controls: boolean;
  loop: '1' | '0';
  aspectRatio: '1/1' | '16/9';
}

export default defineComponent({
  props: {
    blok: { type: Object as () => Props, required: true },
  },
  data() {
    return {
      isPaused: !this.blok.autoplay,
    };
  },
  computed: {
    cssVars(): CSSVars {
      return {
        '--video-aspect-ratio': this.blok.aspectRatio,
      };
    },
  },
  methods: {
    onPlay() {
      this.isPaused = false;
    },
    onPause() {
      this.isPaused = true;
    },
    togglePlayPause() {
      const video = this.$refs.video as HTMLVideoElement;
      if (video && !this.blok.controls) {
        if (this.isPaused) {
          video.play();
        } else {
          video.pause();
        }
      }
    },
  },
});
